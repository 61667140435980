@font-face {
  font-family: 'MontaguSlab';
  src: url(../../fonts/MontaguSlabSemiBold.woff) format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'RalewayBold';
  src: url(../../fonts/Raleway-Bold.woff2) format('woff2'),
      url(../../fonts/Raleway-Bold.woff) format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Raleway';
  src: url(../../fonts/Raleway-Thin.woff2) format('woff2'),
      url(../../fonts/Raleway-Thin.woff) format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
.logo-h2 span {
  font-family: "Playfair";
  
    color :black;
    /* background: -webkit-linear-gradient(#C228F6, #23022E);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
  
  }
  .logo-h2 {
    width: 30vw;;
    color: #2a3736;
    opacity: 1;
    font-family: 'Playfair';
    font-size: 25px;
    max-width: 520px;
    display: flex;
  }
  .name {
    background-image: linear-gradient(to right,   rgb(156, 245, 255),rgb(0, 221, 255),#2a2aff);
    /* background-image: linear-gradient(to right,   #ad06f6,#00e1ff); */
    /* background-color: rgb(0, 221, 255); */
    color: black;
    padding: 10px;
    padding: 10px 20px 10px 20px;
    border-radius: 3px;
    font-family: 'Montserrat';
    font-weight: 700;
  }
  .nav-wrapper-fixed {
    position: fixed;
    width: 100%;
    top : 0px;
    z-index: 1;
  }
  .nav-wrapper-custom {
    padding-top: 18px;
    padding-bottom: 18px;
    font-size: 12px;
    font-family: 'round-edges';
    font-weight: 900;
    width: 100%; 
    display: flex;
    flex-direction: row;
    opacity: 0.8;
    justify-content: space-between;
    align-items: center;
    top:0;

    transition: all .2s ease-in;
    padding-right: 100px;
    padding-left: 5%;
  }
  .hr {
    background-color: rgb(72, 71, 71);
    border: none;
    height: 1px;
  }
  .scrolled{
    
    background-color: #DEE4E7;
    box-shadow: 0px 1px 10px rgb(174, 174, 174);
    transition: all .2s ease-in;
  }
  
  .nav-options {
    /* padding-top: 5px; */
    margin-top: -5px;
    font-family: "Josefin Sans";
   font-size:larger;
   font-weight: 500;
    text-decoration: none;
  }

  .nav-options:hover {
   cursor: pointer; 
  }

  .nav-icon {
    border: none;
    background-color: transparent;
    display: none;
    /* z-index: 3; */
  }

  .nav-dropdown-wrapper {
    position: absolute;
  }
  .nav-dropdown-options {
    width : 100vw;
  }
  .nav-dropdown-options div {
    font-family: 'Josefins-Sans';
    font-weight: 900;
    font-size: 50px;
    color: #573280;
    
  }

  .nav-dropdown-background {
    position: absolute;
    
    top : -200px;
    bottom: 0;
    width:100vw;
    height : 150vh !important;
    background: #ada8b6e3;
    z-index: -1;
  }

  @media screen and (max-width: 769px) {
    .nav-options {
      display: none;
    }
    .nav-wrapper-custom {
      justify-content: space-between;
    }
    .logo-h2 {
      padding-left: 20px;
      font-size: 20px;
    }
    .nav-icon {
      display: block;
      margin-right: 20px;
    }
    .logo-h2 {
      color: #23022E;
    }
  }


  /* //////////////////////////////////////////////////////////////////////////////////////////////////// */

  
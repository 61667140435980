.landing-header-background {
    background-color: #DEE4E7;
}
.landing-header-container{
    background-color: #DEE4E7;
    height: 60vw;
    max-height: 100vh;
    /* max-height: 90vh; */
    margin-left: auto;
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: end;
    position: relative;
}

.link-container{
  display: flex;
  flex-direction: column;
  justify-content:space-around;
  align-items: center;
  /* width: 40px; */
  overflow: hidden;
  /* color: #241722; */
  text-decoration: none;
  color: black;
  text-decoration: none;
}

.link-boxes{
    transform:rotate(90deg);
     font-size:13px;
     font-weight: 500;
     font-family: "Josefin Sans";
     color: #374745;

}


a{
    text-decoration: none;
    
}

.landing-header-text-wrapper {
    position: absolute;
    /* background-color: red; */
    color: #374745;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    /* background-color: red; */
}
.landing-header-text-wrapper h5 {
    font-family: Playfair;
    font-size: 5.7vw;
    letter-spacing: -3px;
}

.landing-header-text-line {
    margin-top: -1vw;
    background-color: rgb(222, 58, 255);
    height: 0.2vw;
    width: 10vw;
    display: block;
    align-self: flex-start;
}

.landing-header-text-p {
    font-size: 1.1vw;
    font-family: Poppins;
    display: block;
    align-self: flex-start;
    margin-top: 1.5vw;
    color: #374745;
}
.landing-header-button-wrapper {
    align-self: flex-start;
    margin-top: 3vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.landing-header-button-slogan{
    font-size: 1.5vw;
    font-family: "Josefin Sans";
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #374745;
}

.landing-header-button-slogan div{
    height: 0.2vw;
    background-color: black;
    width: 8vw;
    display: inline-block;
    margin-left: 1vw;
    opacity: 0.3;

}

.landing-header-button{
    background-color: rgb(255, 33, 255);
    font-family: Poppins;
    font-size: 1vw;
    color: #FFE8FF;
    font-weight: 500;
    border: none;
    padding: 1vw;
    padding-left: 3vw;
    padding-right: 3vw;
    letter-spacing: 3px;
}
.landing-header-img-section-and-top-space-wrapper{
    display: flex;
    flex-direction: column;
    height: 100%;
}
.landing-header-img-top-space{
    height: 95px;
    width: 100%;
}

.landing-header-img-section{
  display: flex;
  height: 100%;
}

.landing-header-img-wrapper {
    background: rgb(255,33,242);
    background: linear-gradient(43deg, rgba(255,33,242,1) 60%, rgb(253, 128, 255) 100%);
    box-shadow: 10px 0px 30px rgb(255, 150, 248);
    height: 100%;
    width: 55vw;
    /* max-height: 90vh; */
    display: flex;
    justify-content: center;
    align-items: end;
    border-top-left-radius: 2vh;
    border-top-right-radius: 2vh;
    overflow: hidden;
}

.landing-header-img {
    /* height: 485px; */
    height: 50vw;
    max-height: 85vh;
    -webkit-filter: drop-shadow(20px 10px 4px #350631);
    filter: drop-shadow(20px 10px 4px #350631);
    /* width: 500px; */
}

@media only screen and (max-width: 775px) {
    .landing-header-container{
        flex-direction: column;
    }
    
    .landing-header-text-wrapper {
        align-self: flex-start;
        justify-self: flex-start;
        margin-top: -20vh;
    }

    .landing-header-container{
       height: 100vh;
       max-height: 850px; 
    }

    .landing-header-text-wrapper h5 {
        font-size: 45px;
        letter-spacing: 0px;
    }
    .landing-header-text-p {
        font-size: 12px;
    }
    .landing-header-img-wrapper {
        width: 70vw;
    }

    .landing-header-img {
        margin-bottom: 75px;
        min-height: 300px;
    }
    .landing-header-button-slogan{
        font-size: 15px;
    }
    .landing-header-button{
        font-size: 10px;
        border: 0.5px solid black;
        margin-right: 10px;
    }

}
@font-face {
  font-family : "round-edges";
  src: local('round-edges'), url(./fonts/VarelaRound-Regular.woff) format('woff');
}

/* @font-face {
  font-family : "Josefins-Sans";
  src: local('round-edges'), url(./fonts/JosefinSans-Medium.woff) format('woff');
} */

@font-face {
  font-family : "RalewayLightItalic";
  src: local('round-edges'), url(./fonts/Raleway-LightItalic.woff) format('woff');
}
@font-face {
  font-family : "RalewayLight";
  src: local('round-edges'), url(./fonts/Raleway-Light.woff) format('woff');
}

@font-face{
  font-family: "AzeretMono";
  src : local('AzeretMono'), url(./fonts/AzeretMono-VariableFont_wght.woff) format('woff');
}
/* @font-face{
  font-family: "Montserrat";
  src : local('AzeretMono'), url(./fonts/Montserrat-Regular.woff) format('woff');
} */
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@1,700&display=swap'); 
@import url('https://fonts.googleapis.com/css2?family=Cormorant:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair:wght@900&display=swap');

/* //:ital,wght@1,700 */

html {
  overflow: -moz-scrollbars-vertical; 
  overflow-y: scroll;
  background-color: white;
}

.App {
  text-align: center;

  
}

.App-header {
  background-color: #ffffff;
  font-size: calc(10px + 2vmin);
  min-height: 100vh;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.img-entry {
  position: absolute;
  height: 200px;
  margin-top: 30vh;
  
}
.projects-carousel-heading-wrapper {
  padding-top: 30px;
  padding-bottom: 20px;
  background-color: #573280;
}
.parent{
  background-color: black; 
  box-shadow: inset 0 3px 10px #222a36;
}
.projects-container{
  /* background-image: url('../public/Projects.jpg'); */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; 
  mix-blend-mode:normal;
  box-shadow: inset 0 30px 100px #222a36;
}

.nav-stick1 {
  overflow: hidden;
}
.below-progress-img {
  width : 100%;
  max-width: 1200px;
  margin: auto;
}
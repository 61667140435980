.skill-container {
    background-image: linear-gradient( black  , #514573, #514573);
}
.skill-content-container {
    background-image: url('../../public/star.png');
    background-size: 400px;
    display: flex;
    padding-top: 20px;
    margin-top: 20px;
    justify-content: space-around;
    align-items: center;
}
.skill-content {
    z-index: 1;
    display: flex;
}
.skill-text-wrapper {
    display: flex;
    align-content: center;
    padding-left: 100px;
    padding-top: 30px;
}
.skill-p {
    font-family: "Josefin Sans";
    font-family: "Josefin Sans";
    font-weight: 700;
    font-size: 150px;
    color: white;
   
}
.skill-cog-img {
    height: 200px;
}

.progress-container {
    background-color: rgba(0, 0, 0, 0.12);
    margin: 3px 3px 0 0;
}

.progress-skill-wrapper {
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 10px;
    padding-top: 15px;
}

.progress-skill-text-wrapper {
    align-self: center;
    padding-left: 10px;
}

.progress-top-heading {
    height : 10px;
}

.progress-icon {
    color: white;
    height: 30px;
    width: 30px;
   
}
.progress-skill {
    font-family: "Josefin Sans";
    color:white;
    font-weight: 600;
}
.progress {
    width: 300px;
    height: 8px !important;
    background-color:rgba(255, 253, 253, 0.738) !important;
    margin-left: 20px;
}
.progress-bar {
    background-color: #6538ae !important;
}

.brain-img-wrapper {
    height: 450px;
}
.brain-img {
    height: 450px;
}

@media screen and (max-width: 1130px) {
    .brain-img-wrapper {
        height: 400px;
    }
    .brain-img {
        height: 400px;
    }
    
}


@media screen and (max-width: 1050px) {
    .brain-img-wrapper {
        height: 350px;
    }
    .brain-img {
        height: 350px;
    }
    
}


@media screen and (max-width: 860px) {
    .brain-img-wrapper {
        height: 300px;
    }
    .brain-img {
        height: 300px;
    }
    
}


@media screen and (max-width: 760px) {
    .brain-img-wrapper {
        height: 250px;
    }
    .brain-img {
        height: 250px;
    }
    
}

@media screen and (max-width: 700px) {
    .brain-img-wrapper {
        height: 300px;
        position: absolute;
    }
    .brain-img {
        height: 300px;
    }

    .skill-text-wrapper {
        display: flex;
        align-content: center;
        padding-left: 25px;
        padding-top: 30px;
    }
    .skill-p {
        font-family: "Josefin Sans";
        font-family: "Josefin Sans";
        font-weight: 700;
        font-size: 75px;
        color: white;
       
    }

}


  .nav-icon-button {
    position: absolute;
    top: 35%;
    right: 5%;
    width: 30px;
    height: 30px;
    transition: all 300ms cubic-bezier(.61, .01, .42, 1);
    cursor: pointer;
    background: transparent;
    border: 0px;
  }
  
  .nav-icon-button div {
    height: 0px;
    border: 1.5px solid #fff;
    width: 22px;
    display: block;
    position: absolute;
    transition: all 300ms cubic-bezier(.61, .01, .42, 1);
    background:#fff;
  }
  
  .nav-icon-button:hover{
    transition-delay: 100ms;
    transform: scale(1.1);
  }
  
  .nav-icon-button:hover .nav-icon-div3:before {
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background: #F26451;
  }
  
  .nav-icon-button:hover div {
    border: 2px solid #fff;
    height: 9px;
    border-radius: 50%;
    margin-left: -1px;
    margin-top: 2px;
    animation: atom 300ms linear 1;
    width: 25px;
    top: 0px;
    background:transparent;
  }
  
  .nav-icon-button:focus {
    outline: 0px;
  }
  
  .nav-icon-div1 {
    top: 0px;
  }
  
  .nav-icon-div2 {
    top: 8px;
  }
  
  .nav-icon-div3 {
    top: 16px;
  }
  
  .nav-icon-div3:before {
    opacity: 0;
    animation: ball 1.5s linear infinite;
    content: '';
    border: 2px solid #fff;
    display: block;
    position: relative;
    top: 0.25px;
  }
  
  .nav-icon-button:hover .nav-icon-div1 {
    transform: rotate(-33deg);
  }
  
  .nav-icon-button:hover  .nav-icon-div2 {
    transform: rotate(90deg);
  }
  
  .nav-icon-button:hover  .nav-icon-div3 {
    transform: rotate(33deg);
  }
  
  .nav-icon-button:hover  .nav-icon-div3:before {
    opacity: 1;
    transition: opacity 600ms cubic-bezier(.61, .01, .42, 1);
    ;
  }
  
  .nav-icon-button:active:hover .nav-icon-div3:before,
  .nav-icon-button:active .nav-icon-div3:before,
  .nav-icon-button:active .nav-icon-div2 {
    opacity: 0;
    transition: all 200ms;
  }
  
  .nav-icon-button:active div {
    border: 1.5px solid #fff;
    height: 0px;
    border-radius: 0%;
    margin-left: -1px;
    margin-top: 6px;
    animation: division 300ms linear 1;
    width: 25px;
    top: 0px;
  }
  
  .nav-icon-button:active .nav-icon-div2 {
    width: 0px;
  }
  
  .nav-icon-button:active .nav-icon-div3 {
    transform: rotate(45deg);
  }
  
  .nav-icon-button:active .nav-icon-div1 {
    transform: rotate(-45deg);
  }
  
  @keyframes atom {
    0% {
      transform: rotate(180deg);
    }
  }
  
  @keyframes division {
    0% {
      transform: rotate(180deg);
    }
  }
  
  @keyframes ball {
    0% {
      left: -20%;
      top: 10%;
    }
    10% {
      left: 10%;
      top: -35%;
    }
    25% {
      left: 45%;
      top: -50%;
    }
    40% {
      left: 80%;
      top: -20%;
    }
    50% {
      left: 98%;
      top: 18%;
    }
    60% {
      left: 80%;
      top: 50%;
    }
    75% {
      left: 45%;
      top: 80%;
    }
    90% {
      left: 0%;
      top: 60%;
    }
    100% {
      left: -20%;
      top: 10%;
    }
  }

  .nav-icon-open {
    position: absolute;
    top: 35%;
    right: 5%;
    width: 30px;
    height: 30px;
    transition: all 300ms cubic-bezier(.61, .01, .42, 1);
    cursor: pointer;
    background: transparent;
    border: 0px; 
    transition-delay: 100ms;
    transform: scale(1.1);
  }
  .nav-icon-open .nav-icon-div3:before {
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background: #F26451;
  }
  .nav-icon-open div {
    height: 0px;
    border: 1.5px solid #fff;
    width: 22px;
    display: block;
    position: absolute;
    transition: all 300ms cubic-bezier(.61, .01, .42, 1);
    background:#fff;
    border: 2px solid #fff;
    height: 9px;
    border-radius: 50%;
    margin-left: -1px;
    margin-top: 2px;
    animation: atom 300ms linear 1;
    width: 25px;
    top: 0px;
    background:transparent;
  }
  .nav-icon-open .nav-icon-div1 {
    transform: rotate(-33deg);
  }
  
  .nav-icon-open  .nav-icon-div2 {
    transform: rotate(90deg);
  }
  
  .nav-icon-open  .nav-icon-div3 {
    transform: rotate(33deg);
  }
  
  .nav-icon-open  .nav-icon-div3:before {
    opacity: 1;
    transition: opacity 600ms cubic-bezier(.61, .01, .42, 1);
    ;
  }

  .nav-icon-open:active .nav-icon-div3:before,
  .nav-icon-open:active .nav-icon-div2 {
    opacity: 0;
    transition: all 200ms;
  }
  .nav-icon-open:active div {
    border: 1.5px solid #fff;
    height: 0px;
    border-radius: 0%;
    margin-left: -1px;
    margin-top: 6px;
    animation: division 300ms linear 1;
    width: 25px;
    top: 0px;
  }
  .nav-icon-open:active .nav-icon-div2 {
    width: 0px;
  }
  
  .nav-icon-open:active .nav-icon-div3 {
    transform: rotate(45deg);
  }
  
  .nav-icon-open:active .nav-icon-div1 {
    transform: rotate(-45deg);
  }